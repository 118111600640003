/* .responsive-width{
  width: 30rem;
}
@media screen and (max-width: 600px) {
  .responsive-width{
    width: 100%;
  }
} */

button {
	background: transparent;
	border: 0;
	padding: 0;
}

.truncate {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp-4 {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp-5 {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp-6 {
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.modal-custom .modal-dialog {
	max-width: 100%;
}

.disabled .page-link {
	background-color: #ddd !important;
}

/* card custom css */
/* .custom-card {
  transition: all 0.1s;
} */

/* .custom-card:hover {
  background-color: rgba(198, 199, 200, 0.2);
} */

/* Upload loader */
.circle-loader {
	margin-bottom: 3.5em;
	border: 2px solid rgba(0, 0, 0, 0.2);
	border-left-color: #5cb85c;
	animation: loader-spin 1.2s infinite linear;
	position: relative;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	width: 7em;
	height: 7em;
}

.load-complete {
	-webkit-animation: none;
	animation: none;
	border-color: #5cb85c;
	transition: border 500ms ease-out;
}

.checkmark {
	display: none;
}

.checkmark.show {
	display: block;
}

.checkmark.draw:after {
	animation-duration: 800ms;
	animation-timing-function: ease;
	animation-name: checkmark;
	transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
	opacity: 1;
	height: 3.5em;
	width: 1.75em;
	transform-origin: left top;
	border-right: 3px solid #5cb85c;
	border-top: 3px solid #5cb85c;
	content: '';
	left: 1.75em;
	top: 3.5em;
	position: absolute;
}

@keyframes loader-spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}

	20% {
		height: 0;
		width: 1.75em;
		opacity: 1;
	}

	40% {
		height: 3.5em;
		width: 1.75em;
		opacity: 1;
	}

	100% {
		height: 3.5em;
		width: 1.75em;
		opacity: 1;
	}
}

.hide-sm {
	display: flex;
}

.show-sm {
	display: none;
}

/* search form */

.form-control.my-search {
	height: fit-content;
	min-width: 500px;
	max-width: 600px;
	padding: 0.5rem 0.9rem !important;
	box-shadow: none !important;
	min-height: 22px !important;
	border-color: #bbb !important;
}

input.form-control.my-search::placeholder {
	color: #999 !important;
}

input.form-control.my-search:focus {
	border-color: #bbb !important;
}

/* #dee2e6 */

/* filter dropdown */
.multiSelectContainer input::placeholder {
	color: #999 !important;
}

.searchWrapper {
	border: 1px solid #bbb !important;
	border-radius: 4px;
	min-height: 22px;
	max-height: 100px;
	padding: 0.36rem 0.8rem !important;
	padding-right: 1.5rem !important;
	overflow-y: auto;
	overflow-x: hidden;
}

/* custom checkbox */
.custom-select-single {
	border-radius: 6px;
	padding: 0.54rem 0.9rem !important;
	display: flex;
	gap: 10px;
	background: #198754;
	color: #fff;
	cursor: pointer;
}

.form-check-label {
	cursor: pointer;
}

.hide-container {
	height: 1px;
	overflow: hidden;
}

.custom-check.form-check-input[type='checkbox'] {
	border-radius: 1.5em;
	background-color: transparent;
}

.custom-check.form-check-input[type='checkbox']:checked {
	background-color: #0d6efd;
}

.custom-input-group {
	width: fit-content;
	display: flex;
	align-items: top;
	gap: 15px;
}

.select-container {
	width: 300px;
	/* overflow: hidden; */
}

.select-all-container {
	display: flex;
	align-items: center;
	gap: 10px;
}

/* custom filter */
.chip {
	background: #5cb85c !important;
	border-radius: 100px !important;
	padding-top: 5px !important;
}

.highlightOption.highlight {
	background: transparent !important;
	color: inherit !important;
}

.multiSelectContainer li:hover {
	background: rgba(92, 184, 92, 0.8) !important;
	color: #fff !important;
}

/* custom add btn */
.custom-add-btn {
	width: 70px;
	height: 70px;
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
	/* animation: bounce 1.5s linear; */
}

.nav-url {
	color: #6c757d;
	text-transform: uppercase;
	text-decoration: none;
	transition: all 0.15s;
}

.nav-url:hover {
	/* color: #6c757d; */
	text-decoration: underline;
}

/* @keyframes bounce {
  0% {
    width: 60px;
    height: 60px;
  }
  100% {
    width: 100px;
  }
} */

/* ---------------MEDIA QUERIES-------------------- */

.modal-custom-filter .modal-dialog {
	max-width: 100%;
}

/* Responsive styles */
@media (max-width: 600px) {
	.modal-custom-filter .modal-dialog {
		max-width: 90vw; /* 95% width on small screens */
	}
}

@media (min-width: 601px) and (max-width: 1200px) {
	.modal-custom-filter .modal-dialog {
		max-width: 70vw; /* 80% width on medium screens */
	}
}

@media (min-width: 1201px) {
	.modal-custom-filter .modal-dialog {
		max-width: 45vw; /* 60% width on large screens */
	}
}

@media (min-width: 768px) {
	.modal-custom .modal-dialog {
		max-width: 50vw;
	}

	.custom-card .md-mr-100 {
		margin-right: 100px;
	}
}

@media (min-width: 780px) {
	.modal-custom .modal-dialog {
		max-width: 70vw;
	}

	.modal-reuseable .modal-dialog {
		max-width: 90vw;
	}
}

@media (max-width: 768px) {
	.hide-sm {
		display: none;
	}

	.show-sm {
		display: flex;
	}

	.form-control.my-search {
		max-width: 100%;
		min-width: auto;
	}

	.select-container {
		width: 100%;
	}

	.select-all-container {
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.select-all-container span.my-info {
		position: absolute;
		bottom: -40px;
		left: 0;
		width: 180%;
		z-index: 9;
	}

	.custom-add-btn {
		width: 60px;
		height: 60px;
	}
}

@media (max-width: 1024px) {
	.custom-input-group {
		width: 100%;
		flex-direction: column;
		align-items: end;
		/* padding: 15px !important; */
		/* border: 1px solid #000; */
	}
}

.css-13cymwt-control {
	background-color: rgb(245, 246, 250);
}

/* Test css for selection an item */
.selectable {
	border-width: 2px !important;
	cursor: pointer;
	transition: border-color 0.3s;
}

.selectable:hover {
	border-color: #007bff !important;
}

.selected {
	border-color: #007bff !important;
	background-color: #d6f5ff !important;
}

.selected-info {
	margin-top: 20px;
	font-size: 18px;
	font-weight: bold;
}
