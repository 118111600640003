.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.template-main-container {
  display: flex;
}
.slider-container {
  min-width: 400px;
  max-width: 50%;
  margin: 25px 0;
  /* border: 1px solid red; */
}

.text-sm-custom {
  color: #aaa;
  font-size: 12px;
}

.a4-note {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.a4-note span.line {
  width: 372px;
  height: 1px;
  background: rgba(204, 204, 204, 0.5);
}
.vertical-text {
  writing-mode: vertical-lr;
}
.paper-container {
  width: 21cm;
  aspect-ratio: 1/1.41;
  padding-top: 37px;
  display: flex;
  border: 1px dashed #ccc;
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  height: 100%;
}
.grid-page {
  position: absolute;
  inset: 0;
  left: -4px;
  top: -2px;
  height: 101%;
  width: 101%;
  background-size: 38px 38px;
  background-image: repeating-linear-gradient(
      0deg,
      transparent,
      transparent 37px,
      #ddd 37px,
      #ddd 38px
    ),
    repeating-linear-gradient(
      -90deg,
      transparent,
      transparent 37px,
      #ddd 37px,
      #ddd 38px
    );
}
.ticket-container {
  width: fit-content;
  min-width: 1%;
  max-width: 49.606rem;
  cursor: pointer;
}
.product-container {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-right: 18px; */
  font-size: 14px;
}
.template-checkbox-container {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
.remove-container {
  position: absolute;
  color: #444;
  top: 2px;
  right: 16px;
  transition: all 0.1s;
}
.remove-container:hover {
  color: red;
}
.custom-select-single.disabled {
  background: #1a8e58;
}
.custom-select-single.enabled {
  background: #0f7746;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  background: #eee;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 2px solid #9fdae4;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: transparent;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

@media (min-width: 1024px) {
  .template-checkbox-container {
    max-width: 515px;
  }
}
