.printable-product-container {
	text-align: center;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.printable-main .react-grid-item:not(.react-grid-placeholder) {
	background: transparent !important;
	border: 0;
}

.printable-main:first-child {
	margin-top: 0px !important;
}
.printable-main:first-child {
	margin-bottom: 0px !important;
}
